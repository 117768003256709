import getRiskLevelsForCounty from './getRiskLevelsForCounty';

const isMobile = window.matchMedia("only screen and (max-width: 768px)").matches;

let countySelector = document.getElementById('countySelector');
countySelector.addEventListener("change", function () {
    let countyId = countySelector.options[countySelector.selectedIndex].value;
    getRiskLevelsForCounty(countyId, isMobile);
});

// Make home Look Out Risk popin same width as the sidebar's

// 1 - get sidebar width
//  a/ check if #risk-container-width element exists (if so, #desktop-risk should exists as well)
//  b/ if #risk-container-width exists, get its width in a var called 'w' for instance

// 2 - Assign the 'w' to #desktop-risk elem .style.width

// 3 - Redo step 1b and 2 if the window's width change ('onresize()')
const riskContainer = document.getElementById('risk-container-width');
if (riskContainer !== null) {
    resizeSideBar(riskContainer.offsetWidth);

    window.onresize = function(event) {
        let w = riskContainer.offsetWidth;
        resizeSideBar(w);
    };
}


function resizeSideBar(width) {
    document.getElementById('desktopRisk').style.width = width;
}