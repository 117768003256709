import React, {Component, Fragment} from 'react';
import VictoryChart from './IEFriendlyVictoryChart';
import {VictoryBar, VictoryTheme, VictoryAxis, VictoryZoomContainer} from 'victory';

class ChartRisk extends Component {

    render() {
        let commonView =  <Fragment>
            <h5><b>{this.props.risk.countyName} ({this.props.risk.countyNumber})</b></h5>

            <VictoryChart
                theme={VictoryTheme.material}
                domainPadding={{ x:[15,15] }}
                width={350}
                height={750}
                padding={{ top: 0, bottom:50, left: 110, right: 10}}
                containerComponent={
                    <VictoryZoomContainer/>
                }
            >
                <VictoryBar horizontal
                            data={this.props.risk.risks}
                            x="pollenName"
                            y="level"
                            style= {{
                                data: {
                                    fill: evt => {
                                        switch (evt.datum.level) {
                                            case 1:
                                                return '#048000';
                                            case 2:
                                                return '#f2ea1a';
                                            case 3:
                                                return '#FF0200';
                                            default:
                                                return '#FFFFFF';
                                        }
                                    }
                                }
                            }}
                />

                <VictoryAxis
                    style={{
                        grid: {stroke: "none"},
                        axis: {stroke: "transparent"},
                        ticks: {stroke: "#666", size: 5},
                        tickLabels: {fontSize: 15, padding: 5}
                    }}
                />
                <VictoryAxis
                    label="Impact sanitaire prévisionnel des pollens"
                    tickValues={[0,1,2,3]}
                    style={{
                        grid: {
                            stroke: "#666",
                            strokeDasharray: "0"
                        },
                        axis: {stroke: "#666"},
                        axisLabel: {fontSize: 15, padding: 30},
                        ticks: {stroke: "#666", size: 5},
                        tickLabels: {fontSize: 15, padding: 5}
                    }}
                    dependentAxis
                />

            </VictoryChart>
        </Fragment>

        if (this.props.isMobile) {
            return (
                <div className="risk-container">
                    <div className="button-close-mobile-container">
                        <button className="button-close" id="buttonCloseModal">
                            <span className="sr-only"> Fermer </span>
                            <i aria-hidden="true"> &times; </i>
                        </button>
                    </div>
                    <div className="mobile-risk-svg-container">
                    {commonView}
                    </div>
                </div>
            )
        } else {
            return (
                <Fragment>
                    <span className="petite-fleche"></span>
                    <p className="text-right">
                    <button className="button-close" id="buttonCloseModal">
                        <span className="sr-only"> Fermer </span>
                        <i aria-hidden="true"> &#10006; </i>
                        </button>
                    </p>
                    <div className="risk-container">
                    {commonView}
                    </div>
                </Fragment>
        )

        }
    }
}

export default ChartRisk;