require('core-js');
require('font-awesome/css/font-awesome.min.css');
require('./css/flexgrid.css');
require('./scss/main.scss');

require('./js/vigilanceMapGraph');
require('./js/homepageRedirection');
require('./js/tabs.js');

require('./js/cookies.js');

let loaded = false;
let loadedRegionMap = false;
let isIframe = !!document.getElementById('vigilance-map-iframe');

// Allow to check if mobile, or if small iframe
function isMobileDevice() {
    return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1)
        || (isIframe && window.matchMedia("only screen and (max-width: 768px)").matches);
}

function loadVigilanceMap() {
    let xmlhttp = new XMLHttpRequest();
    xmlhttp.onreadystatechange = function() {
        if (xmlhttp.readyState === XMLHttpRequest.DONE) {   // XMLHttpRequest.DONE == 4
            if (xmlhttp.status === 200) {
                let json = JSON.parse(xmlhttp.responseText);

                var vigilanceMapCounties = JSON.parse(json.vigilanceMapCounties);
                var franceMap = JSON.parse(json.geoJsonMaps.france);
                var andorraMap = JSON.parse(json.geoJsonMaps.andorra);
                var ileDeFranceMap = JSON.parse(json.geoJsonMaps.ileDeFrance);

                require.ensure([
                    './js/vigilanceMap'
                ], (require) => {
                    let x = require('./js/vigilanceMap');
                    x.default(vigilanceMapCounties,franceMap,andorraMap,ileDeFranceMap, isIframe);
                });

                document.getElementById('vigilanceMap').style.opacity = "1";
            }
            else {
                console.error('There was an error' + xmlhttp.status);
            }
        }
    };

    xmlhttp.open("GET", "/load_vigilance_map", true);
    xmlhttp.send();
}

// If Mobile, we let the PNG image, else we load the interactive map
if (!isMobileDevice()) {
    if (staticVigilanceMapExist === "" || isIframe) {
        loadVigilanceMap()
    } else {
        document.getElementById('vigilanceMap').addEventListener("mouseenter", function () {
            if (loaded) {
                return;
            }

            this.style.opacity = "0.6";
            loaded = true;
            loadVigilanceMap();
        });
    }

    require.ensure([
        './js/scroll-to-top',
        'leaflet/dist/leaflet.css',
        'leaflet/dist/leaflet.js'
    ], (require) => {
        require('./js/scroll-to-top');
        require('leaflet/dist/leaflet.css');
        require('leaflet/dist/leaflet.js');
    });

}

const regionMapTab = document.getElementById('aTab2');
if (regionMapTab) {
    regionMapTab.addEventListener("click", function () {
        if (loadedRegionMap) {
            return;
        }

        loadedRegionMap = true;
        loadRegionMap();
    });
}

function loadRegionMap() {
    let xmlhttp = new XMLHttpRequest();
    xmlhttp.onreadystatechange = function() {
        if (xmlhttp.readyState === XMLHttpRequest.DONE) {   // XMLHttpRequest.DONE == 4
            if (xmlhttp.status === 200) {
                document.getElementById('tab2').innerHTML = xmlhttp.responseText;
            }
            else {
                console.error('There was an error' + xmlhttp.status);
            }
        }
    };

    xmlhttp.open("GET", "/regions/iframe/integration/france", true);
    xmlhttp.send();
}