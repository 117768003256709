const homeCitySelector = document.getElementById('footer-villes');
const homePollenSelector = document.getElementById('footer-pollens');

if (homeCitySelector !== null) {
    homeCitySelector.addEventListener("change", function () {
        let cityId = homeCitySelector.options[homeCitySelector.selectedIndex].value;
        window.location.href = (locale !== 'fr') ?
            "/" + locale + "/risks/cities/" + cityId
        : "/les-risques/risques-par-ville/" + cityId;
    });
}

if (homePollenSelector !== null) {
    homePollenSelector.addEventListener("change", function () {
        let pollenId = homePollenSelector.options[homePollenSelector.selectedIndex].value;
        window.location.href = (locale !== 'fr') ?
            "/" + locale + "/historics/" + (new Date()).getFullYear() + "/pollens/" + pollenId
            : "/les-risques/risques-par-pollen/" + pollenId + "/" + (new Date()).getFullYear();
    });
}