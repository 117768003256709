import ChartRisk from "./components/ChartRisk";
import React from "react";
import ReactDOM from 'react-dom';

function getRiskLevelsForCounty(countyNumber, isMobile) {
    if (countyNumber === "#") {
        return;
    }

    let modal = (isMobile) ? document.getElementById("mobileRisk") : document.getElementById("desktopRiskFromSelector");
    let xmlhttp = new XMLHttpRequest();
    xmlhttp.onreadystatechange = function() {
        if (xmlhttp.readyState == XMLHttpRequest.DONE) {   // XMLHttpRequest.DONE == 4
            if (xmlhttp.status == 200) {
                ReactDOM.render(
                    <ChartRisk risk={JSON.parse(xmlhttp.response)} isMobile={isMobile}/>,
                    modal
                );
                modal.classList.add('open');

                let buttonCloseModal = document.getElementById("buttonCloseModal");
                buttonCloseModal.addEventListener("click", function () {
                    modal.classList.remove("open");
                });
            }
            else {
                console.error('There was an error' + xmlhttp.status);
            }
        }
    };

    xmlhttp.open("GET", "/risks/thea/counties/" + countyNumber, true);
    xmlhttp.send();
}

export default getRiskLevelsForCounty;